/* Roboto 300 */
@font-face {
  font-family: "Roboto";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* Roboto 400 */
@font-face {
  font-family: "Roboto";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* Roboto 500 */
@font-face {
  font-family: "Roboto";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  background-color: #e0e0e0;
}

html,
#root,
body {
  height: 100vh;
}

.shadow-bottom {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.shadow-top {
  box-shadow: 0px -3px 5px -1px rgba(0, 0, 0, 0.2), 0px -6px 10px 0px rgba(0, 0, 0, 0.14), 0px -1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.shadow-right {
  box-shadow: 3px 0px 5px -1px rgba(0, 0, 0, 0.2), 6px 0px 10px 0px rgba(0, 0, 0, 0.14), 1px 0px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.shadow-left {
  box-shadow: -3px 0px 5px -1px rgba(0, 0, 0, 0.2), -6px 0px 10px 0px rgba(0, 0, 0, 0.14), -1px 0px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
